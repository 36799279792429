import axios from 'axios';

//export const url_backend = `http://127.0.0.1:8000`
export const url_backend = `https://tooly-api.redbms.es`

export const auth = {
    autologin: function (autolog) {
        return axios.post(url_backend + `/api/users/autologin`, {
            autolog: autolog,
            plataforma: 'Usuarios'
        })
    },
    logout: function (perfilId) {
        return axios.post(url_backend + `/api/users/logout`, {
            perfilId: perfilId,
            plataforma: 'Usuarios'
        })
    }
}

export const user = {
    getUser: function (id) {
        return axios.get(url_backend + `/api/users/user/${id}`)
    },
    getOtherUser: function (cod, id) {
        return axios.get(url_backend + `/api/users/getOtherUser/${cod}/${id}`)
    },
    getAllUser: function (id) {
        return axios.post(url_backend + `/api/users/getAllUser`, {
            perfilId: id
        })
    },
    updateUser: function (id, nickname, descripcion, color, notificaciones) {
        return axios.post(url_backend + `/api/users/updateUser/${id}`, {
            nickname: nickname,
            descripcion: descripcion,
            color: color,
            notificaciones: notificaciones
        })
    },
    darPuntos: function (perfil_puntuado, perfil_puntua, puntos, razon) {
        return axios.post(url_backend + `/api/users/darPuntos`, {
            perfil_puntuado: perfil_puntuado,
            perfil_puntua: perfil_puntua,
            puntos: puntos,
            razon: razon
        })
    },
    marcarFavorito: function (seguido, seguidor, esFav) {
        return axios.post(url_backend + `/api/users/marcarFavorito`, {
            perefilSeguidoId: seguido,
            perfilId: seguidor,
            esFav: esFav
        })
    },
    getNotificaciones: function (id) {
        return axios.post(url_backend + `/api/users/notificaciones`, {
            perfilId: id
        })
    },
    leerNotificacion: function (id, cod) {
        return axios.post(url_backend + `/api/users/leerNotificacion/${id}`, {
            perfilId: cod
        })
    },
    leerTodo: function (cod) {
        return axios.post(url_backend + `/api/users/leerTodo`, {
            perfilId: cod
        })
    }
}

export const cursos = {
    getAll: function (cod) {
        return axios.post(url_backend + `/api/cursos/getAll`, {
            perfilId: cod,
            userId: cod,
        });
    },
    getCurso: function (id, cod) {
        return axios.post(url_backend + `/api/cursos/getCurso/${id}`, {
            perfilId: cod
        });
    },
    getLinks: function (cod) {
        return axios.post(url_backend + `/api/cursos/links`, {
            perfilId: cod
        });
    },
    newComentario: function (id, curso, comentario, adjuntos, fileBlob, lng, idComment) {
        return axios.post(url_backend + `/api/cursos/newComentario`, {
            perfilId: id,
            cursoId: curso,
            texto: comentario,
            adjuntos: adjuntos,
            fileBlob: fileBlob,
            lng: lng,
            comentarioId: idComment,
        });
    },
    postFileBlob: function (formData, onUploadProgress) {
        return axios.post(url_backend + `/api/cursos/uploadBlob`, formData, {
            headers: {
                'content-type': 'application/octet-stream',
            },
            onUploadProgress
        });
    },
    postFile: function (formdata, onUploadProgress) {
        //axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend + `/api/cursos/upload`, formdata, {
            headers: {
                'content-type': 'multipart/form-data',
            },
            onUploadProgress
        })
    },
    postFileComment: function (formdata, onUploadProgress) {
        //axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend + `/api/cursos/uploadComment`, formdata, {
            headers: {
                'content-type': 'multipart/form-data',
            },
            onUploadProgress
        })
    },
    getFile: function (file, cod) {
        return axios.get(url_backend + `/api/media/documents/${cod}/${file}`)
    },
    recursoFavorito: function (id, curso, recurso, tipo) {
        return axios.post(url_backend + `/api/cursos/recursoFavorito`, {
            perfilId: id,
            cursoId: curso,
            recursoId: recurso,
            tipo: tipo
        });
    },
    likeComment: function (id, comentarioId) {
        return axios.post(url_backend + `/api/cursos/likeComment`, {
            perfilId: id,
            comentarioId: comentarioId
        });
    },
    compruebaDocumentos: function(id) {
        return axios.post(url_backend + `/api/cursos/compruebaDocumentos`, {
            perfilId: id
        });
    },
    compruebaDocumentosComments: function(id) {
        return axios.post(url_backend + `/api/cursos/compruebaDocumentosComments`, {
            perfilId: id
        });
    }
}

export const seguimientos = {
    getSeguimientoVideo: function (cursoId, videoId, perfilId) {
        return axios.post(url_backend + `/api/seguimientos/getSeguimientoVideo`, {
            cursoId: cursoId,
            videoId: videoId,
            perfilId: perfilId
        })
    },
    updateSegVideo: function (data) {
        return axios.post(url_backend + `/api/seguimientos/updateSegVideo`, data)
    },
    getSeguimientoPDF: function (cursoId, docId, perfilId) {
        return axios.post(url_backend + `/api/seguimientos/getSeguimientoPdf`, {
            cursoId: cursoId,
            documentoId: docId,
            perfilId: perfilId
        })
    },
    updateSegPdf: function (data) {
        return axios.post(url_backend + `/api/seguimientos/updateSegPdf`, data)
    },
    addVisitaLink: function (cod, link) {
        return axios.post(url_backend + `/api/seguimientos/addVisitaLink`, {
            perfilId: cod,
            linkId: link
        })
    },
    addVisitaWebinar: function (cod, curso) {
        return axios.post(url_backend + `/api/seguimientos/addVisitaWebinar`, {
            perfilId: cod,
            cursoId: curso
        })
    }
}

export const soporte = {
    addSoporte: function (cod, asunto, formacion, texto) {
        return axios.post(url_backend + `/api/soporte/addSoporte`, {
            perfilId: cod,
            asunto: asunto,
            formacion: formacion,
            texto: texto
        })
    }
}