import { CronJob } from "cron"
import { user } from './../Data/data'

/**
 * Obtenemos las crecenciales y token necesarios para las llamadas
 */
let isRunning = false
let cod = window.localStorage.getItem('cod') ? parseInt(window.localStorage.getItem('cod')) : 0
// Fin 
/**
 * * cronJobAlertas
 * Cada 1 min se va a hacer una consulta a las alertas pendientes si esta online para ver cuantas tenemos sin leer
 * para establecer un badge icon en alertas
 */
const cronJobAlertas = new CronJob("*/1 * * * *", async () => {  //new CronJob("*/5 * * * * ", async () => { -> se ejecutaría cada 5 minutos
    if (cod === 0 || cod != localStorage.getItem('cod')) {
        cod = window.localStorage.getItem('cod') ? parseInt(window.localStorage.getItem('cod')) : 0
    }
    if (!isRunning) {
        isRunning = true
        if (window.location.pathname == '/perfil') {
            user.getNotificaciones(cod).then(response => {
                const alertasNoReaded = response.data.notificaciones.filter(alerta => alerta.leido == 0)
                localStorage.setItem('alertsNoReaded', alertasNoReaded.length);
            })
                .catch(err => console.warn(err))
        }
        isRunning = false
    }
})
cronJobAlertas.start()